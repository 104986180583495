import {
  QueryClient,
  QueryClientProvider,
  useQueryErrorResetBoundary,
} from "@tanstack/react-query";
import {
  Outlet,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Cookies from "js-cookie";
import * as Toast from "@radix-ui/react-toast";
import * as Sentry from "@sentry/react";
import "./index.css";
import { AuthContext } from "./hooks/auth";
import React from "react";

Sentry.init({
  dsn: "https://ab102208475b4486a45892df05ce2077@o1422776.ingest.sentry.io/6769803",
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      ),
    }),
  ],
  tracesSampleRate: 0.1,
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      suspense: true,
    },
  },
});

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const router = sentryCreateBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        index: true,
        lazy: () => import("./routes"),
      },
      {
        path: "/servers",
        lazy: () => import("./routes/servers/layout"),
        children: [
          {
            index: true,
            lazy: () => import("./routes/servers"),
          },
          {
            path: ":guild_id",
            lazy: () => import("./routes/servers/guild_id/layout"),
            children: [
              {
                index: true,
                lazy: () => import("./routes/servers/guild_id"),
              },
              {
                path: "rankbinds",
                lazy: () =>
                  import("./routes/servers/guild_id/rankbinds/layout"),
                children: [
                  {
                    index: true,
                    lazy: () => import("./routes/servers/guild_id/rankbinds"),
                  },
                  {
                    path: "new",
                    lazy: () =>
                      import("./routes/servers/guild_id/rankbinds/new"),
                  },
                ],
              },
              {
                path: "groupbinds",
                lazy: () =>
                  import("./routes/servers/guild_id/groupbinds/layout"),
                children: [
                  {
                    index: true,
                    lazy: () => import("./routes/servers/guild_id/groupbinds"),
                  },
                  {
                    path: "new",
                    lazy: () =>
                      import("./routes/servers/guild_id/groupbinds/new"),
                  },
                ],
              },
              {
                path: "custombinds",
                lazy: () => import("./routes/servers/guild_id/custombinds"),
              },
              {
                path: "assetbinds",
                lazy: () =>
                  import("./routes/servers/guild_id/assetbinds/layout"),
                children: [
                  {
                    index: true,
                    lazy: () => import("./routes/servers/guild_id/assetbinds"),
                  },
                  {
                    path: "new",
                    lazy: () =>
                      import("./routes/servers/guild_id/assetbinds/new"),
                  },
                ],
              },
              {
                path: "blacklists",
                lazy: () =>
                  import("./routes/servers/guild_id/blacklists/layout"),
                children: [
                  {
                    index: true,
                    lazy: () => import("./routes/servers/guild_id/blacklists"),
                  },
                  {
                    path: "new",
                    lazy: () =>
                      import("./routes/servers/guild_id/blacklists/new"),
                  },
                ],
              },
              {
                path: "tokens",
                lazy: () => import("./routes/servers/guild_id/tokens"),
              },
              {
                path: "tower",
                lazy: () => import("./routes/servers/guild_id/tower/layout"),
                children: [
                  {
                    index: true,
                    lazy: () =>
                      import("./routes/servers/guild_id/tower/settings"),
                  },
                  {
                    path: "xp",
                    lazy: () =>
                      import("./routes/servers/guild_id/tower/xp/layout"),
                    children: [
                      {
                        path: "binds",
                        lazy: () =>
                          import("./routes/servers/guild_id/tower/xp/binds"),
                      },
                      {
                        path: "users",
                        children: [
                          {
                            index: true,
                            lazy: () =>
                              import(
                                "./routes/servers/guild_id/tower/xp/users"
                              ),
                          },
                          {
                            path: ":roblox_id",
                            lazy: () =>
                              import(
                                "./routes/servers/guild_id/tower/xp/users/[roblox_id]"
                              ),
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                path: "premium",
                lazy: () => import("./routes/servers/guild_id/premium"),
              },
            ],
          },
        ],
      },
      {
        path: "profile",
        lazy: () => import("./routes/profile/layout"),
        children: [
          {
            index: true,
            lazy: () => import("./routes/profile"),
          },
          {
            path: "verify",
            lazy: () => import("./routes/profile/verify"),
          },
        ],
      },
    ],
  },
]);

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Toast.Provider>
        <RouterProvider router={router} />
      </Toast.Provider>
    </QueryClientProvider>
  );
}

function Layout() {
  const auth = Cookies.get("rowifi_auth");
  const { reset } = useQueryErrorResetBoundary();

  return (
    <AuthContext.Provider value={auth}>
      <Sentry.ErrorBoundary
        onReset={reset}
        fallback={({ error }) => (
          <>
            Oh no!
            {error.message}
            {error.stack}
            {error.cause}
          </>
        )}
      >
        <Outlet />
        <Toast.Viewport className="fixed bottom-0 right-0 flex flex-col p-8 w-[390px] z-50 outline-none" />
      </Sentry.ErrorBoundary>
    </AuthContext.Provider>
  );
}

export default App;
